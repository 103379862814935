import React from "react";
// import { content, title } from "src/copy/terms";

// const sections = [
//   { id: "ownership", title: "Ownership" },
//   { id: "media-release", title: "Media Release" },
//   { id: "confidential-information", title: "Confidential Information" },
//   { id: "information-sharing", title: "Information Sharing" },
//   { id: "submissions", title: "Submissions" },
//   { id: "general", title: "General" },
// ];

const Terms: React.FC = () => <h1>Terms</h1>;

export default Terms;
